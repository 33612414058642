import {getUserInfo} from "network/user"
const actions={
    remUser:({commit,state},user)=>{
        return new Promise((resolve,reject)=>{
            try{
                if(user===null)
                    throw new Error("state.user 对象不能为null")

                commit('ADD_USER',user)
            }catch(e){
                reject(e)
            }
            resolve(state.user)
        })
    },
    getUserInfomation:async ({commit,state})=>{
        if(state.user==null||state.user.name=="")
            return
        await getUserInfo(state.user.name).then(
            res=>{
                if(res.userName!=null)
                commit('SET_USER_NAME',res.userName)
            },e=>{console.log(e)}
        )
    }

}

export default actions