import {deepCopy} from '../assets/js/utils/utils.js'

const mutations={
    SET_USER:(state,payload)=>{
        state.user=deepCopy(payload)
    },
    SET_USER_NAME:(state,name)=>{
        state.user.info.name=name
    }
}

export default mutations
