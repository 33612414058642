const deepCopy=function(obj){
    let newobj=null
    if(typeof(obj)=='object'&&typeof (obj)!==null)
    {
        if(obj instanceof String ||obj instanceof Number||obj instanceof Boolean){
            newobj=baseCopy()
            return newobj
        }
        if(obj instanceof Date)
        {
            return new Date(obj.valueOf())
        }
        newobj=obj instanceof Array ? []:{}
        for(let i in obj)
        newobj[i]=deepCopy(obj[i])
    }else{
        newobj=obj
    }
return newobj
}

const baseCopy=(obj)=>{
    return obj.valueOf()
}



const getLastMonth=()=>{
    let now=new Date()
    let year=now.getUTCFullYear()
    let month=now.getUTCMonth()
    if(month-1<0)
      year-=1

    month=(month-1)%12
    if(month>9)
    month=new String (month)
    else
    month="0"+month
    return new String(year)+month
}

const getCurrentTime = ()=>{
var now = new Date();

// 获取年、月、日、时、分
var year = now.getFullYear();
var month = (now.getMonth() + 1).toString().padStart(2, '0'); // 月份从0开始，需要加1，并且保证两位数
var day = now.getDate().toString().padStart(2, '0'); // 保证两位数
var hours = now.getHours().toString().padStart(2, '0'); // 保证两位数
var minutes = now.getMinutes().toString().padStart(2, '0'); // 保证两位数

// 获取星期几
var daysOfWeek = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'];
var dayOfWeek = daysOfWeek[now.getDay()];

// 格式化时间
var formattedTime = year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ' ' + dayOfWeek;

// 输出格式化后的时间
return formattedTime
}

export {deepCopy,getLastMonth,getCurrentTime}
