import Instance from "./axios"


const getCheckItems=()=>{

    return new Promise((resolve,reject)=>{
        Instance.get('/info/checkItem/list').then(
            res=>{
                resolve(res.data)
            },
            e=>{reject(e.response)}
        )
    })
}

const getCheckItem=(name)=>{

    return new Promise((resolve,reject)=>{
        Instance.get('info/checkItem/single/'+name).then(
            res=>{
                if(res.data.status==1)
                resolve(res.data.content)
                else
                reject(res.data.content)
            },
            e=>{reject(e.response)}
        )
    })
}
export {getCheckItem,getCheckItems}