import { getAreaInfo, getSubareaList } from "network/area.js"


const state = () => (
    {
        province: [],
        city: [],
        area: []
    }
)
const getters = {
    provinces: state => state.province,
    cities: state => state.city,
    areas: state => state.area,
    areaName:state=>(id)=>{
        let p = state.province.find(a => { return a[0]==id })
        if (p)
            return p[1]
        let c = state.city.find(a => { return a[0]==id })
        if (c)
            return c[1]
        let a = state.area.find(i => { return i[0]==id })
        if (a) 
            return a[1]
    },
    area: state => (name) => {
        let p = state.province.find(a => { return a[1].includes(name) })
        if (p)
            return p
        let c = state.city.find(a => { return a[1].includes(name) })
        if (c)
            return c
        let a = state.area.find(i => { return i[1].includes(name) })
        if (a) 
            return a
    },
    subCities: state => (id) => {
        if (id.length > 2)
            id = id.substring(0, 2)
        return state.city.filter(a => a[0].startsWith(id))
    },
    subArea: state => (id) => {
        if (id.length > 4)
            id = id.substring(0, 4)
        return state.area.filter(a => a[0].startsWith(id))
    }

}
const mutations = {
    ADD_AN_AREA: (state, load) => {
        if (load.area_id.endsWith('0000'))
            if (!state.province.find(a => { return a[0] == load.area_id }))
                state.province.push(load)
            else if (load.area_id.endsWith('00'))
                if (!state.city.find(a => { return a[0] == load.area_id }))
                    state.city.push(load)
                else
                    if (!state.area.find(a => { return a[0] == load.area_id }))
                        state.area.push(load)
    },
    CLEAR_ALL_AREA: (state) => {
        state.area = []
        state.city = []
        state.province = []
    },
    ADD_AREAS: (state, areas) => {
        let area_id, area_name
        for (let load of areas) {
            if (load instanceof Array) {
                area_id = load[0]
                area_name = load[1]
            }
            else if (load instanceof Object) {
                area_id = load.area_id
                area_name = load.areas
            }
            state.area.push([area_id, area_name])
        }
    },
    ADD_CITIES: (state, areas) => {
        let area_id, area_name
        for (let area of areas) {
            if (area instanceof Array) {
                area_id = area[0]
                area_name = area[1]
            }
            else if (area instanceof Object) {
                area_id = area.area_id
                area_name = area.areas
            }
            state.city.push([area_id, area_name])
        }
    },
    ADD_PROVINCE: (state, areas) => {
        let area_id, area_name
        for (let area of areas) {
            if (area instanceof Array) {
                area_id = area[0]
                area_name = area[1]
            }
            else if (area instanceof Object) {
                area_id = area.area_id
                area_name = area.areas
            }
            state.province.push([area_id, area_name])
        }
    }
}
const actions = {
    clear: ({ commit }) => {
        return new Promise((resolve, reject) => {
            try {
                commit('CLEAR_ALL_AREA')
                resolve()
            } catch (e) {
                reject(e)
            }
        })
    },
    initArea: ({ rootGetters, commit,dispatch ,state}) => {
        if (rootGetters.getUserRole === "org" || rootGetters.getUserAuth.length == 0)
            return
        let auths = rootGetters.getUserAuth
        let promise = []
        let provinces = []
        let cities = []
        let counties = []
        for (let auth of auths) {
            if (auth.match(/\d{2}0000/))
                provinces.push(auth)
            else if (auth.match(/\d{4}00/))
                cities.push(auth)
            else if (auth.match(/\d{6}/))
                counties.push(auth)
        }

        if (provinces.length > 0)
            for (let p of provinces)
                promise.push(getSubareaList(p).then(res => { return res.data }, e => { return e }))

        Promise.allSettled(promise).then(res => {
            res.forEach(element => {
                if (element.status === 'fulfilled')
                    element.value.forEach(e => {
                        cities.push(e.area_id)
                    })
                else
                    console.log(element.reason)
            })
            let promise = []
            if (cities.length > 0)
                cities.forEach(e => {
                    promise.push(getSubareaList(e).then(res => { return res.data }, e => { return e }))
                })
            Promise.allSettled(promise).then(results => {
                results.forEach(element => {
                    if (element.status === 'fulfilled')
                        element.value.forEach(e => {
                            counties.push(e.area_id)
                        })
                    else
                        console.log(element.reason)
                })
                let promise = []
                promise.push(getAreaInfo(provinces).then(res => { return res.data }, e => { return e }))
                promise.push(getAreaInfo(cities).then(res => { return res.data }, e => { return e }))
                promise.push(getAreaInfo(counties).then(res => { return res.data }, e => { return e }))
                Promise.all(promise).then(results => {
                    let promise=[]
                    promise.push(dispatch('addProvinces',results[0]).then(res=>{},e=>{return e}))
                    promise.push(dispatch('addCities',results[1]).then(res=>{},e=>{return e}))
                    promise.push(dispatch('addAreas',results[2]).then(res=>{},e=>{return e}))
                    
                    Promise.allSettled(promise).then(res=>{
                        res.forEach(e=>{
                            if(e.status!=='fulfilled')
                            console.log(e)
                        })
                    })
                }, reason => {
                    console.log("AREA INIT FAILED:" + reason)
                })
            })
        })
    },
    addCities: ({ state, commit }, areas) => {
        return new Promise(
            (resolve, reject) => {
                if (!areas instanceof Array)
                    reject(new Error('Property Areas Type Must be \'Array\''))
                else
                    if (areas.length <= 0)
                        reject(new Error('Property Areas is NULL,Useless Code'))
                let map = new Map(state.city)
                for (let area of areas) {
                    map.set(area.area_id, area.area_name)
                }
                try {
                    commit('ADD_CITIES', map.entries())
                    resolve(map.entries())
                } catch (e) {
                    reject(e)
                }
            }
        )
    },
    addProvinces: ({ state, commit }, areas) => {
        return new Promise(
            (resolve, reject) => {
                if (!areas instanceof Array)
                    reject(new Error('Property Areas Type Must be \'Array\''))
                else
                    if (areas.length <= 0)
                        reject(new Error('Property Areas is NULL,Useless Code'))
                let map = new Map(state.province)
                for (let area of areas) {
                    map.set(area.area_id, area.area_name)
                }
                try {
                    commit('ADD_PROVINCE', map.entries())
                    resolve(map.entries())
                } catch (e) {
                    reject(e)
                }
            }
        )
    },
    addAreas: ({ state, commit }, areas) => {
        return new Promise(
            (resolve, reject) => {
                if (!areas instanceof Array)
                    reject(new Error('Property Areas Type Must be \'Array\''))
                else
                    if (areas.length <= 0)
                        reject(new Error('Property Areas is NULL,Useless Code'))

                let map = new Map(state.area)
                for (let area of areas) {
                    map.set(area.area_id, area.area_name)
                }
                try {
                    commit('ADD_AREAS', map.entries())
                    resolve(map.entries())
                } catch (e) {
                    reject(e)
                }
            }
        )
    }

}


export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}