import axios from "axios";
import router from "router/index.js"

const Instance=axios.create({
    baseURL:"/dataplateform",
    timeout:20000,
})
Instance.interceptors.request.use(
    configure=>{
        if(localStorage.currentLogin)
        configure.headers.Authorization="Bearer "+localStorage.currentLogin

        return configure
    },
    error=>{
        return Promise.reject(error)
    }
)
Instance.interceptors.response.use(response=>{


    return response
},error=>{
    if (error.response) {
        console.log('axios:' + error.response.status);
        switch (error.response.status) {
          case 401:
            // 返回 401 清除token信息并跳转到登录页面
            localStorage.removeItem('currentLogin')
            router.replace({
              name:'登录'});
        }
      }
    return Promise.reject(error)
})

export default Instance