import { deepCopy } from "assets/js/utils/utils.js";

const decode=function(data){
    let user = JSON.parse(window.atob(data.split(".")[1]));
    let authInfo = user.scope.split(" ");
    let auth = deepCopy(authInfo);
    authInfo.reverse().pop();

    return  {
        name: user.sub,
        token: data,
        role: auth[0],
        auth: authInfo,
        info:user
      }
}

export {decode} 