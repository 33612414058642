import Instance from "./axios.js";

const getUserInfo=(userName)=>{
    return new Promise(
       ( resolve,reject)=>{
        try{
            Instance.get('/users/getUserInfo/'+userName).then(
                res=>{resolve(res.data)},e=>{reject(e)}
            )
        }catch(e){
            reject(e)
        }
        
       }
    )
}
const getUserListByInfo=(name,pageSize,pageNo)=>{
        return new Promise(
            (resolve,reject)=>{
                if(name===''||name===undefined)
                Instance.get('/users/getUserList/'+pageNo).then(
                    res=>{
                        if(res.data.dataList.length>0)
                        resolve(res.data)
                        else
                        reject(new Error('Access success but No data'))
                    },e=>{reject(e)}
                )
                else
                Instance.post('/users/getUserList',{name,pageSize,pageNo}).then(
                    res=>{
                        if(res.data.dataList.length>0)
                        resolve(res.data)
                        else
                        reject(new Error('Access success but No data'))
                    },e=>{reject(e)}
                )
            }
        )
}

const modifyAccessAuth=(userId,accessAuth)=>{
    return new Promise(
        (resolve,reject)=>{
            try{
                Instance.post('/users/authModify',{userId,accessAuth}).then(
                    res=>{resolve(res.data.content)},
                    e=>{reject(e)}
                )
            }catch(e){
                reject(e)
            }
        }
    )
}

export {getUserInfo,modifyAccessAuth,getUserListByInfo}