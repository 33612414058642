import { createStore } from 'vuex'
   const state=()=>({
        routes:[]
    })
    const getters={
        tabRoutes:state=>state.routes
    }
    const mutations={
        ADD_TAB:(state,tabRoute)=>{
            state.routes.push({
                name:tabRoute.name,
                path:tabRoute.path,
                title:tabRoute.meta.title||tabRoute.name
            })
        },
        DELETE_TAB:(state,tabRoute)=>{
            for(let [i,t] of state.routes.entries())
            {
                if(t.path===tabRoute.path){
                    state.routes.splice(i,1)
                    break
                }
            }
        },
        CLEAN_TAB:(state)=>{
            state.routes=[]
        }
    }
    const actions={
        tabClose:({state,commit},tabRoute)=>{
            return new Promise((resolve)=>{
                commit('DELETE_TAB',tabRoute)
                resolve([...state.routes])
            })
        },
        addTab:({state,commit},tabRoute)=>{
            return new Promise((resolve,reject)=>{
                for(let t of state.routes)
                if(t.path===tabRoute.path)
                {
                    reject(tabRoute)
                    return
                }
                commit('ADD_TAB',tabRoute)
                resolve([...state.routes])
            })
    },
    clearTab:({state,commit})=>{
        return new Promise((resolve,reject)=>{
            try{
                if(state.routes.length>=0)
                commit('CLEAN_TAB')
                resolve()
            }catch(e){
                reject(e)
            }
        })
    }
    }


export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
  }