import Instance from "./axios.js"

const getCityList = (province) => {
    return Instance.get("/locations/cityList/" + province)
}

const getCountyList = (city) => {
    return Instance.get("/locations/" + "countyList/" + city)
}

const getSubareaList = (area) => {
    if (area === undefined || area === null)
        throw new Error("传入参数错误")
    return Instance.get("/locations/subAreaList/" + area)
}

const getAreaInfo = function (area) {
    
    return new Promise((resolve, reject) => {
        if (area === undefined || area === null)
            reject (new Error("传入参数错误"))
        let ids = []
        if (area instanceof Array){
            if(area.length==0){
                resolve({data:[]})
                return
            }
            else
            ids = area
        }
        else
            ids.push(area)
        Instance.post('/locations/AreaInfo', ids).then(res=>{
            if(res)resolve(res)
            else reject(new Error('Serve No Result'))
        },e=>{reject(e)})
    })
}
export { getCityList, getCountyList, getSubareaList, getAreaInfo }