import {createLogger,createStore}from 'vuex'
import tabsRoutes from './modules/tabsRoutes'
import companies from './modules/company'
import areas from './modules/area'
import checkItems from './modules/checkItem'
import mutations from './mutation'
import actions from './action'
import { decode } from '../assets/js/utils/token'

const debug = process.env.NODE_ENV !== 'production'


const  state=()=>{
  let token= localStorage.getItem("currentLogin")
  if(token===undefined||token===null)
  return{
    user:{
      name:"Anonymous",   
    token:'',
    role:'',
    auth:[],
    info:{}
    }
  }
  let u=decode(token)
  return {
    user:u
  }
}

const getters= {

    getUserInfo:state=>state.user.info,
    getUserOtherName:state=>state.user.info.name,
    getUserName: (state) => {
      return state.user.name;
    },
    getUserToken :(state)=>{
      return state.user.token
    },
    getUserRole:(state)=>{
      return state.user.role
    },
    getUserAuth:(state)=>{
      return state.user.auth
    }

  }
 const store= createStore({
    state,
    getters,
  actions,
  mutations,
  modules: {
    tabsRoutes,
    companies,
    areas,
    checkItems
  },
  strict: debug,
  plugins: debug ? [createLogger()] : []
})

store.dispatch("getUserInfomation")
store.dispatch("areas/initArea")
store.dispatch("companies/initCompanies")
store.dispatch("checkItems/initCheckItem")

export default store