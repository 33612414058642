import { getOrgDetail } from "network/org.js";


const state=()=>({
    company:{}
})
const getters={
    userCompanies:state=>state.company
}
const mutations={
    ADD_A_COMPANY:(state,com)=>{
        if(state.company[com.area_id])
        {
            if(state.company[com.area_id].findIndex(a=>{a.org_id===com.org_id})>=0)  
            state.company[com.area_id].push(
                {'org_id':com.org_id,'org_name':com.org_name}
            )
            else{
                throw new Error(area_id+'Already Exist')
            }
        }
        else
        state.company[com.area_id]=[{'org_id':com.org_id,'org_name':com.org_name}]
    },                 
    CLEAR_ALL_COMPANY:(state)=>{
        state.company={}
    },
    INIT_COMPANIES:(state,coms)=>{
        let com={}
        for(let item of coms)
        {
            if(com[item.area_id])
                com[item.area_id].push({'org_name':item.org_name,'org_id':item.org_id})
            else
                com[item.area_id]=[{'org_name':item.org_name,'org_id':item.org_id}]  
        }
        state.company=com
    }
}
const actions={
    clear:({commit})=>{
        return new Promise((resolve,reject)=>{
           try{ commit('CLEAR_ALL_COMPANY')
            resolve()}catch(e)
            {
                reject(e)
            }
        })
    },
    addCompany:({state,commit},com)=>{
        return new Promise((resolve,reject)=>{ 
            try{
                if(com instanceof Object)
                if(com.area_id instanceof String&&com.org_id&&com.org_name instanceof String)
                {
                    commit('ADD_A_COMPANY',com)
                    resolve[state.company]
                }
            reject(new Error('Store Action addCompany() Param Invalid:'+com))
            }catch(e)
            {
                reject(e)
            }
        })
    },
    initCompany:({state,commit},coms) =>{
        return new Promise((resolve,reject)=>{
            if(state.company==undefined||state.company==null||state.company=={}) reject(new Error('State \'company\' Has Already Been init'))
            else{
                try{
                   commit('INIT_COMPANIES',coms)
                }catch(e){
                    reject(e)
                }
            }
               
        })
    },
    initCompanies:async ({rootGetters,state,commit,dispatch})=>{
        //清理当前存储,
        try{
            commit('CLEAR_ALL_COMPANY')
        }catch(e){
            console.log('缓存清理失败')
            console.log(e)
        }

        let auth=rootGetters.getUserAuth
        //只处理登录为org
        if(rootGetters.getUserRole!=='org'||auth.length===0)
        return 
        let orgList
        try{
           orgList= await getOrgDetail(auth)
        }catch(e){
            console.log("网络请求错误")
            console.log(e)
        }
        
        try{
            commit('INIT_COMPANIES',orgList.data)
        }catch(e)
        {
            console.log("初始化失败'INIT_COMPANIES'")
            console.log(e)
        }
    }
}


export default {
namespaced: true,
state,
getters,
actions,
mutations
}