import { getCheckItems } from "network/info.js";


const state=()=>({
    checkItemList:[]
})
const getters={
   checkItemList:state=>state.checkItemList,
}
const mutations={
    INIT_CHECKITEMS:(state,items)=>{
        if(! items instanceof Array)
            throw new Error("数据不合法NOT ARRAY")
        let set=new Set()
        for(let item of items )
        {
            if( typeof item =='string')
            set.add(item)
            else
            {
                set.clear()
                throw new Error("数据不合法 NOT STRING")
            }
        }
        state.checkItemList=Array.from(set)
        
    },
    CLEAR_ALL:(state)=>{
        state.checkItemList=[]
    }
}
const actions={
    clear:({commit})=>{
        return new Promise((resolve,reject)=>{
           try{ commit('CLEAR_ALL')
            resolve()}catch(e)
            {
                reject(e)
            }
        })
    },
    initCheckItem:(({commit})=>{
        getCheckItems().then(res=>{
            try{
                commit("INIT_CHECKITEMS",res)
            }catch (e){
                console.log("vuex commit error:"+e.message)
            }
        },
        e=>{console.log(e)}
        )
    })
}


export default {
namespaced: true,
state,
getters,
actions,
mutations
}