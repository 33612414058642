import Instance from "./axios.js";


const getOrgDetail=(org)=>{
   
   if(org instanceof Array)

   return Instance.post("/org/orgDetails",org)

   else
      {
         return Instance.get("/org/orgDetails/"+org)
      }
}

const addOrganization=(org)=>{
   return new Promise(
      (resolve,reject)=>{
         if(org.org_name==undefined||org.org_name=='')
            reject(new Error('Property orgId Could Not Be Null'))
         if(org.area_id==undefined||org.area_id=='')
            reject(new Error('Property areaId Could Not Be Null'))

         Instance.put("/org/add",org).then(
            res=>{
               resolve(res.data)
            },
            e=>{
               reject(e.response)
            }
         )
      }
   )
}

const getOrgByName=(name)=>{
   return new Promise(
      (resolve,reject)=>{
         if(name==undefined||name=="")
            reject(new Error("传参不能为空"))
         Instance.get('/org/orgDetailsByName/'+name).then(
            res=>{
               if(res.data.length>=0)
               resolve(res.data)
               else
               reject(new Error("没有该单位"))
            },
            e=>{
               reject(e)
            }
         )
      }
   )
}

const getOrgWithBindByArea=(areaId)=>{
  return new Promise(
   (resolve,reject)=>{
      if(areaId instanceof Array)
      {
         Instance.post('org/orgAuth/byArea',areaId).then(
            res=>{resolve(res.data)},e=>{reject(e)}
         )
      }else{
         Instance.post('org/orgAuth/byArea',[areaId]).then(
            res=>{
               if(res.data[areaId])
               resolve(res.data[areaId])
               else
               reject(new Error('该区域下无单位'))
            },e=>{reject(e)}
         )
      }
   }
  )
}
const bindUploadAccount=(org)=>{
   return new Promise(
      (resolve,reject)=>{
         if(org.userId==null||org.userId==''||org.password==null||org.password=='')
            reject(new Error('Counld not bind user of Null'))
         if(org.orgId==null||org.orgId=='')
            reject(new Error('Counld not bind org of Null'))
         
         Instance.post('/org/bind',org).then(
            res=>{
               resolve(res.data)
            },
            reject=>{
               reject(e)
            }
         )
      }
   )
}

export{getOrgDetail,addOrganization,getOrgWithBindByArea,bindUploadAccount,getOrgByName}