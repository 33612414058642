import { createRouter, createWebHistory } from 'vue-router'
import store from '../store'

const routes = [
  {
    path: '/',
    redirect: {
      name: '登录'
    }
  },
  {
    path: '/Welcome',
    name: 'Welcome',
    component: () => import('../views/login/Welcome.vue'),
    children:[
      {
        path:'login',
        name:'登录',
        component:()=>import('../views/login/LoginForm.vue'),
        props:route=>({
          Id:route.query.Id,
          password:route.query.pw
      })
      },
      {
        path:'sign',
        name:'注册',
        component:()=>import('../views/login/SignForm.vue')
      }
    ]
  },
  {
    path: '/test',
    name: 'Test',
    component: () => import('../views/Test.vue')

  },
  {
    path: '/reports',
    name: 'reports',
    props: route => { id: route.query.id },
    component: () => import('../views/search/DataDetail.vue')
  },
  {
    path: '/index',
    name: 'Index',
    component: () => import('../views/Index.vue'),
    children: [
      {
        path: '',
        redirect: {
          name: '首页'
        }
      },
      {
        path: 'home',
        name: '首页',
        component: () => import('../views/home/Home.vue'),
        meta:{
          keepAlive:false
        }
      },
      {
        path: 'addorg',
        name: '添加组织',
        component: () => import('../views/settings/AddOrg.vue'),
        meta:{
          title:'添加单位',
          keepAlive:false
        }
      },
      {
        path: 'uploader',
        name: '上传绑定',
        component: () => import('../views/settings/Uploader.vue'),
        meta:{
          title:'上传绑定',
          keepAlive:false
        },
        props:route=>({
            orgId:route.query.orgId,
            orgName:route.query.orgName
        })
      },
      {
        path: 'grant',
        name: '授权',
        component: () => import('../views/settings/Grant.vue'),
        meta:{
          title:'账号授权',
          keepAlive:true
        }
      },
      {
        path: 'userManage',
        name: '账号管理',
        component: () => import('../views/settings/UserManage.vue'),
        meta:{
          title:'账号管理',
          keepAlive:true
        }
      },
      {
        path: 'search',
        name: '查询',
        component: () => import('../views/search/Search.vue'),
        meta:{
          keepAlive:true,
          title:"快速查询"
        }
      },
      {
        path: 'upload',
        name: '数据上传',
        component: () => import('../views/search/Upload.vue'),
        meta:{
          keepAlive:true,
          title:"数据上传"
        }
      },
      {
        path: 'region',
        name: '地区查询',
        component: () => import('../views/search/Region.vue'),
        meta:{keepAlive:true,title:'区域查询'}
      },
      {
        path: 'audit',
        name: '不合格查询',
        component: () => import('../views/search/Audit.vue'),
        meta:{keepAlive:true,title:"专项查询"}
      },
      {
        path: 'cityamount',
        name: '城市数据统计',
        component: () => import('../views/report/CityAmount.vue'),
        meta:{
          title:'数据统计',keepAlive:true
        }
      },
      {
        path: 'report',
        name: '报表',
        component: () => import('../views/report/Report.vue'),
        meta:{
          title:'数据统计',keepAlive:true
        }
      },
      {
        path: 'screen',
        name: '大屏展示',
        component: () => import('../views/report/Screen.vue'),
        meta:{
          title:'大屏'
        }
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory('/'),
  routes
})
router.beforeEach((to,from,next)=>{
  if(to.name=='注册')
  {
    next()
    return
  }
    if(localStorage.getItem('currentLogin'))
    {
      let loginInfo=store.getters.getUserInfo
      if(loginInfo.exp==undefined)
      if(to.name="登录")
        next();
        else
        next({name:"登录"})
      else
        if(loginInfo.exp>(Date.now()/1000))
          if(to.name=='登录')
          next({name:'首页'})
          else
          next()
        else{
          localStorage.removeItem("currentLogin")
        if(to.name="登录")
        next();
        else
        next({name:"登录"})}
    }else
    {
      if(to.name=='登录')
      next()
      else
      next({name:"登录"})
    }
  
})

export default router
